<template>
  <b-container fluid>
    <div class="row p-0 m-0" style="background: #fff;">
      <div class="col-sm-3">{{ $t('pay.plan') }}:</div>
      <div class="col-sm-9">
        <h4>{{ planName }} <span v-if="planEnd" style="font-size: 12px;">({{ $t('pay.expire') }} {{ planEnd }})</span>
          <b-button class="btn btn-primary" @click="showAvailablePlans()">Upgrade</b-button></h4>
      </div>
      <div class="col-sm-3">{{ $t('pay.employees') }}:</div>
      <div class="col-sm-9">
        {{ employeesCurrent }} / {{ employeesAvailable }}
        <b-progress :value="employeesCurrent" :max="employeesAvailable" class="mb-3"></b-progress>
      </div>
      <div class="col-sm-3">{{ $t('pay.services') }}:</div>
      <div class="col-sm-9">
        {{ servicesCurrent }} / {{ servicesAvailable }}
        <b-progress :value="servicesCurrent" :max="servicesAvailable" class="mb-3"></b-progress>
      </div>
      <div class="col-sm-3">{{ $t('pay.bookings_today') }}:</div>
      <div class="col-sm-9">
        {{ bookingsCurrent }} / {{ bookingsAvailable }}
        <b-progress :value="bookingsCurrent" :max="bookingsAvailable" class="mb-3"></b-progress>
      </div>
      <div class="col-sm-3">{{ $t('pay.emails') }}:</div>
      <div class="col-sm-9">
        {{ emailsCurrent }} / {{ emailsAvailable }}
        <b-progress :value="emailsCurrent" :max="emailsAvailable" class="mb-3"></b-progress>
      </div>
      <div class="col-sm-3">{{ $t('pay.sms') }}:</div>
      <div class="col-sm-9">
        {{ smsCurrent }} / {{ smsAvailable }}
        <b-progress :value="smsCurrent" :max="smsAvailable" class="mb-3"></b-progress>
      </div>
      <div class="col-sm-3">{{ $t('pay.space') }}:</div>
      <div class="col-sm-9">
        {{ spaceCurrent }} / {{ spaceAvailable }} MB
        <b-progress :value="spaceCurrent" :max="spaceAvailable" class="mb-3"></b-progress>
      </div>

    </div>
    <b-modal id="availablePlans" size="xl" :title="$t('pay.upgrade_plan')" scrollable static centered no-close-on-backdrop hide-footer>
      <b-row>
        <b-col v-for="(item,index) in cards" :key="index">
          <iq-card body-class="text-center" class="thePlans">
            <template v-slot:body>
              <h3 class="mb-4 font-weight-bolder planName">
                {{ item.name }}
              </h3>
              <span class="font-size-16 text-uppercase planPrice" v-if="item.custom_app !== 1">{{ item.price_monthly }} EUR</span>
              <span class="font-size-16 text-uppercase planPrice" v-if="item.custom_app === 1">Contact us</span>
              <ul class="list-unstyled line-height-4 mb-0">
                <li>{{ item.booking === '-1' ? $t('pay.unlimited') : item.booking }} {{ $t('pay.bookings_per_day') }}</li>
                <li>{{ item.services === '-1' ? $t('pay.unlimited') : item.services }} {{ $t('pay.services') }}</li>
                <li>{{ item.employees === '-1' ? $t('pay.unlimited') : item.employees }} {{ $t('pay.employees') }}</li>
              </ul>
              <b-button variant="primary" class="mt-5" v-if="item.id === planId && item.id !== '5'">
                {{ $t('pay.you_have_this_plan') }}
              </b-button>
              <b-button variant="primary" class="mt-5" v-if="item.id !== planId && item.id !== '5'">
                <router-link :to="'/app/buyplan/' + item.id" class="text-white">
                  {{ $t('pay.upgrade_plan') }}
                </router-link>
              </b-button>
              <b-button variant="primary" class="mt-5" v-if="item.id === '5'" @click="contactUsCustom">
                {{ $t('pay.contact_us') }}
              </b-button>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal id="contactUsCustom" size="lg" :title="$t('pay.contact_us')" scrollable static centered no-close-on-backdrop hide-footer>
      <div class="row" v-if="successMessage === ''">
        <div class="col-sm-12">
          {{ $t('pay.company_name') }}:
          <b-form-input type="text" :placeholder="$t('pay.company_name')" v-model="companyName"></b-form-input>
        </div>
        <div class="col-sm-12">
          {{ $t('pay.company_domain') }}:
          <b-form-input type="text" :placeholder="$t('pay.company_domain')" v-model="companyDomain"></b-form-input>
        </div>
        <div class="col-sm-12">
          {{ $t('pay.company_email') }}:
          <b-form-input type="text" :placeholder="$t('pay.company_email')" v-model="companyEmail"></b-form-input>
        </div>
        <div class="col-sm-12">
          {{ $t('pay.company_phone') }}:
          <b-form-input type="text" :placeholder="$t('pay.company_phone')" v-model="companyPhone"></b-form-input>
        </div>
        <div class="col-sm-12">
          <span style="display:block; font-size: 12px;margin-bottom: 10px;color: red;" v-if="errorL !== ''">{{ errorL }}</span>
          <span style="font-size: 12px;margin-bottom: 10px">{{ $t('pay.fill_details') }}</span>
        </div>
        <div class="col-sm-12 text-center">
          <b-button variant="primary" class="mt-5" @click="contactUsSending">
            {{ $t('pay.contact_us') }}
          </b-button>
        </div>
      </div>
      <div class="row" v-if="successMessage !== ''">
        <div class="col-sm-12">
          {{ $t('pay.success_message') }}
        </div>
      </div>
    </b-modal>
  </b-container>
</template>

<script>

import Axios from 'axios'
import { API } from '@/config/api.config'
import { core } from '@/config/pluginInit'
import { Browser } from '@capacitor/browser'
import { Capacitor } from '@capacitor/core'

export default {
  name: 'Program',
  mounted () {
    core.index()
    this.getPlanInfo()
  },
  data () {
    return {
      device: Capacitor.getPlatform() || 'web',
      apiToken: localStorage.getItem('api_token') || '',
      companyToken: localStorage.getItem('company_token') || '',
      plandId: 1,
      planName: 'Free',
      planEnd: '',
      employeesAvailable: 0,
      employeesCurrent: 0,
      servicesAvailable: 0,
      servicesCurrent: 0,
      bookingsAvailable: 0,
      bookingsCurrent: 0,
      emailsAvailable: 0,
      emailsCurrent: 0,
      smsAvailable: 0,
      smsCurrent: 0,
      spaceAvailable: 0,
      spaceCurrent: 0,
      cards: [],
      companyName: '',
      companyDomain: '',
      companyPhone: '',
      companyEmail: '',
      successMessage: '',
      errorL: ''
    }
  },
  methods: {
    openInNewBrowser (url) {
      const openCapacitorSite = async () => {
        await Browser.open({ url: url + '/' + this.companyToken })
      }
      console.log(openCapacitorSite())
    },
    getPlanInfo () {
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_COMPANY_PLAN_INFO)
        .then((response) => {
          if (response.data.status === 'success') {
            this.planId = response.data.planInfo.id
            this.planName = response.data.planInfo.name
            this.planEnd = response.data.planDetails.planEnd
            this.employeesAvailable = response.data.planInfo.employees
            this.employeesCurrent = response.data.planDetails.employees
            this.servicesAvailable = response.data.planInfo.services
            this.servicesCurrent = response.data.planDetails.services
            this.bookingsAvailable = response.data.planInfo.bookings
            this.bookingsCurrent = response.data.planDetails.bookings
            this.emailsAvailable = response.data.planInfo.emails
            this.emailsCurrent = response.data.planDetails.emails
            this.smsAvailable = response.data.planInfo.sms
            this.smsCurrent = response.data.planDetails.sms
            this.spaceAvailable = response.data.planInfo.space
            this.spaceCurrent = response.data.planDetails.space
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
    },
    showAvailablePlans () {
      const thisOne = this
      Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
      Axios.post(API.API_GET_UPGRADE_PLANS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.cards = response.data.plans
          }
        })
        .catch((error) => {
          console.error(error.response)
          if (error.response && error.response.status === 401) {
            this.$router.push({ name: 'auth1.login' })
          }
        })
      thisOne.$bvModal.show('availablePlans')
    },
    contactUsCustom () {
      this.$bvModal.hide('availablePlans')
      this.$bvModal.show('contactUsCustom')
    },
    contactUsSending () {
      this.errorL = ''
      if (this.companyName === '') {
        this.errorL = this.$t('pay.company_name_missing')
      } else if (this.companyDomain === '') {
        this.errorL = this.$t('pay.company_domain_missing')
      } else if (this.companyEmail === '') {
        this.errorL = this.$t('pay.company_email_missing')
      } else if (this.companyPhone === '') {
        this.errorL = this.$t('pay.company_phone_missing')
      }
      if (this.errorL === '') {
        const postData = {
          name: this.companyName,
          service: this.companyDomain,
          email: this.companyEmail,
          phone: this.companyPhone
        }
        Axios.defaults.headers.common.Authorization = 'bearer ' + this.apiToken
        const thisOne = this
        this.successMessage = ''
        Axios.post(API.API_SEND_UPGRADE_REQUEST, postData)
          .then((response) => {
            if (response.data.status === 'success') {
              thisOne.successMessage = this.$t('pay.success_message')
              setTimeout(function () {
                this.$bvModal.hide('availablePlans')
                this.$bvModal.hide('contactUsCustom')
              }, 5000)
            }
          })
          .catch((error) => {
            console.error(error.response)
            if (error.response && error.response.status === 401) {
              this.$router.push({ name: 'auth1.login' })
            }
          })
      }
    }
  }
}
</script>

<style scoped>
.planName{
  background: #000000;
  color:#fff !important;
  border-radius: 3px;
}
.planPrice{
  font-weight: bold;
}
.thePlans{
  box-shadow: 0px 0px 12px 0px rgb(0 0 0 / 6%) !important;
  background: #1272c5 !important;
  color:#fff !important;
}
</style>
